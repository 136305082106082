"use client";

import { stegaClean } from "@sanity/client/stega";
import * as styles from "./styles.css";
import Link from "next/link";
import Icon from "@/elements/icons";

interface CommonProps {
  style:
    | "primary"
    | "secondary"
    | "secondaryInvert"
    | "neutral"
    | "tertiary"
    | "tertiaryInvert"
    | "primaryCircular"
    | "secondaryCircular";
  size?: "large" | "small";
  type?: "submit"
}

type ConditionalProps =
  | {
      href?: string;
      label?: string;
      onClick?: never;
      disabled?: never;
      target?: string;
      circular?: "left" | "right" | "play";
      rollerProductID?: string;
      className?: string;
      linkType?: "internal link" | "external link" | "roller ID" | string;
    }
  | {
      onClick?: React.MouseEventHandler<HTMLButtonElement>;
      href?: never;
      label?: string;
      disabled?: boolean;
      circular?: "left" | "right" | "play";
      rollerProductID?: string;
      className?: string;
      noLabelMobile?: boolean;
      linkType?: "internal link" | "external link" | "roller ID" | string;
    };

type ButtonProps = CommonProps & ConditionalProps;

const handleRollerClick = (rollerProductID: string) => {
  if (typeof window !== "undefined" && window.RollerCheckout) {
    if (rollerProductID) {
      window.RollerCheckout.showProduct({ productId: rollerProductID });
    } else {
      window.RollerCheckout.show();
    }
  } else {
    console.error("RollerCheckout is not available.");
  }
};

export default function Button(props: ButtonProps) {
  if (props.href && props?.linkType && props?.linkType !== 'roller ID') {
    return (
      <Link
        href={props?.href ?? '/'}
        className={`${styles.container({
          style: props.style,
          size: props.size,
        })} is--button is--${props.style} ${props.className}`}
        target={props.href.includes("http") ? "_blank" : "_self"}
        prefetch={false}
      >
        {props.label}
        {(props.style === "tertiary" || props.style === "tertiaryInvert") && (
          <div className={styles.icon}>
            <Icon type="arrow-sm-right-filled" />
          </div>
        )}
        {props.style.toLowerCase().includes("circular") && (
          <>
            {props?.circular && props?.circular === "right" && (
              <div className={styles.icon}>
                <Icon type="arrow-sm-right-filled" />
              </div>
            )}
            {props?.circular && props?.circular === "left" && (
              <div className={styles.icon}>
                <Icon type="arrow-sm-left-filled" />
              </div>
            )}
          </>
        )}
      </Link>
    );
  }
  return (
    <button
      className={`${styles.container({
        style: props.style,
        size: props.size,
        type: props.label ? "text" : "icon",
      })} ${props.className} is--${props.style}`}
      onClick={
        props.linkType === "roller ID"
          ? () => handleRollerClick(stegaClean(props.rollerProductID) || "")
          : props.onClick || (() => {})
      }
      type={props.type}
      disabled={props.disabled}
    >
      {!props.style.toLowerCase().includes("circular") && props.label && (
        <span>{props.label}</span>
      )}
      {!props.style.toLowerCase().includes("circular") &&
        (!props.label ||
          props.style === "tertiary" ||
          props.style === "tertiaryInvert") && (
          <div className={styles.icon}>
            <Icon type="arrow-sm-right-filled" />
          </div>
        )}

      {props.style.toLowerCase().includes("circular") && (
        <>
          {props?.circular && props?.circular === "right" && (
            <div className={styles.icon}>
              <Icon type="arrow-sm-right-filled" />
            </div>
          )}
          {props?.circular && props?.circular === "left" && (
            <div className={styles.icon}>
              <Icon type="arrow-sm-left-filled" />
            </div>
          )}
          {props?.circular && props?.circular === "play" && (
            <div className={styles.icon}>
              <Icon type="play-filled" />
            </div>
          )}
        </>
      )}
    </button>
  );
}
