import * as styles from "./styles.css";
import AcademicCapFilled from "./filled/academic-cap.svg";
import ArrowRightFilled from "./filled/arrow-right.svg";
import ArrowSmLeftFilled from "./filled/arrow-sm-left.svg";
import ArrowSmRightFilled from "./filled/arrow-sm-right.svg";
import CalendarDaysFilled from "./filled/calendar-days.svg";
import ChatAltFilled from "./filled/chat-alt.svg";
import CheckCircleFilled from "./filled/check-circle.svg";
import ChevronDownFilled from "./filled/chevron-down.svg";
import ChevronLeftFilled from "./filled/chevron-left.svg";
import ChevronRightFilled from "./filled/chevron-right.svg";
import ChevronUpFilled from "./filled/chevron-up.svg";
import ClockFilled from "./filled/clock.svg";
import ExclamationCircleFilled from "./filled/exclamation-circle.svg";
import MailFilled from "./filled/mail.svg";
import MapPinFilled from "./filled/map-pin.svg";
import MapFilled from "./filled/map.svg";
import PhoneFilled from "./filled/phone.svg";
import PlayFilled from "./filled/play.svg";
import VideoCameraFilled from "./filled/video-camera.svg";
import XFilled from "./filled/x.svg";
import ArrowLeftUnfilled from "./unfilled/arrow-left.svg";
import ChevronDownUnfilled from "./unfilled/chevron-down.svg";
import ChevronRightUnfilled from "./unfilled/chevron-right.svg";
import ChevronLeftUnfilled from "./unfilled/chevron-left.svg";
import ChevronUpUnfilled from "./unfilled/chevron-up.svg";
import ExternalLinkUnfilled from "./unfilled/external-link.svg";
import HomeUnfilled from "./unfilled/home.svg";
import MenuAlt3Unfilled from "./unfilled/menu-alt-3.svg";
import SearchUnfilled from "./unfilled/search.svg";
import TicketUnfilled from "./unfilled/ticket.svg";

interface IconProps {
  type:
    | "academic-cap-filled"
    | "arrow-right-filled"
    | "arrow-sm-left-filled"
    | "arrow-sm-right-filled"
    | "calendar-days-filled"
    | "chat-alt-filled"
    | "check-circle-filled"
    | "chevron-down-filled"
    | "chevron-left-filled"
    | "chevron-right-filled"
    | "chevron-up-filled"
    | "clock-filled"
    | "exclamation-circle-filled"
    | "mail-filled"
    | "map-pin-filled"
    | "map-filled"
    | "phone-filled"
    | "play-filled"
    | "video-camera-filled"
    | "x-filled"
    | "arrow-left-unfilled"
    | "chevron-down-unfilled"
    | "chevron-right-unfilled"
    | "chevron-left-unfilled"
    | "chevron-up-unfilled"
    | "external-link-unfilled"
    | "home-unfilled"
    | "menu-alt-3-unfilled"
    | "search-unfilled"
    | "ticket-unfilled";
}

export default function Icon(props: IconProps) {
  return (
    <div className={styles.container}>
      {props.type === "academic-cap-filled" && <AcademicCapFilled />}
      {props.type === "arrow-right-filled" && <ArrowRightFilled />}
      {props.type === "arrow-sm-left-filled" && <ArrowSmLeftFilled />}
      {props.type === "arrow-sm-right-filled" && <ArrowSmRightFilled />}
      {props.type === "calendar-days-filled" && <CalendarDaysFilled />}
      {props.type === "chat-alt-filled" && <ChatAltFilled />}
      {props.type === "check-circle-filled" && <CheckCircleFilled />}
      {props.type === "chevron-down-filled" && <ChevronDownFilled />}
      {props.type === "chevron-left-filled" && <ChevronLeftFilled />}
      {props.type === "chevron-right-filled" && <ChevronRightFilled />}
      {props.type === "chevron-up-filled" && <ChevronUpFilled />}
      {props.type === "clock-filled" && <ClockFilled />}
      {props.type === "exclamation-circle-filled" && (
        <ExclamationCircleFilled />
      )}
      {props.type === "mail-filled" && <MailFilled />}
      {props.type === "map-pin-filled" && <MapPinFilled />}
      {props.type === "map-filled" && <MapFilled />}
      {props.type === "phone-filled" && <PhoneFilled />}
      {props.type === "play-filled" && <PlayFilled />}
      {props.type === "video-camera-filled" && <VideoCameraFilled />}
      {props.type === "x-filled" && <XFilled />}
      {props.type === "arrow-left-unfilled" && <ArrowLeftUnfilled />}
      {props.type === "chevron-down-unfilled" && <ChevronDownUnfilled />}
      {props.type === "chevron-right-unfilled" && <ChevronRightUnfilled />}
      {props.type === "chevron-left-unfilled" && <ChevronLeftUnfilled />}
      {props.type === "chevron-up-unfilled" && <ChevronUpUnfilled />}
      {props.type === "external-link-unfilled" && <ExternalLinkUnfilled />}
      {props.type === "home-unfilled" && <HomeUnfilled />}
      {props.type === "menu-alt-3-unfilled" && <MenuAlt3Unfilled />}
      {props.type === "search-unfilled" && <SearchUnfilled />}
      {props.type === "ticket-unfilled" && <TicketUnfilled />}
    </div>
  );
}
